import SectionHeader from "../sectionHeader"
import React from "react"
import ValeurElement from "./valeurElement"

const ValeursSection = () => (
  <div id="valeurs-section" className="col-md-12 mt-5">
    <SectionHeader title="Valeurs" whiteText={false} inversed={false}/>
    <div className="row">
      <div className="col-md-3 d-flex justify-content-center">
        <ValeurElement title="Intégrité" elements={[
          'Être honnête (avec soi et les autres)',
          'Avoir toujours de bonnes intentions',
          'Rester impartial et raisonner avec objectivité',
          'Se rapeller d\'où l\'on vient'
        ]}/>
      </div>
      <div className="col-md-3 d-flex justify-content-center">
        <ValeurElement title="Réalisation" elements={[
          'Se connaître soi-même',
          'Remettre en question ses croyances',
          'Identifier ses moteurs et les exploiter',
          'Dépasser son Ego',
          'Dépasser les limites'
        ]}/>
      </div>
      <div className="col-md-3 d-flex justify-content-center">
        <ValeurElement title="Devoir" elements={[
          'Accepter que tout dépende de soi',
          'Reconnaître et assumer ses erreurs',
          'Analyser ses échecs et ses réussites',
          'Être exemplaire'
        ]}/>
      </div>
      <div className="col-md-3 d-flex justify-content-center">
        <ValeurElement title="Courage" elements={[
          'Faire ce qui est Juste',
          'Décider',
          'Mettre en Action sa Pensée',
          'S\'affirmer auprès de soi et des autres',
          'Prendre des Risques mesurés'
        ]}/>
      </div>
    </div>
  </div>
)

export default ValeursSection