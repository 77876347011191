import React from "react"

const CultureContent = () => (
  <div className="p-5">
    <h3 className="font-weight-bold text-black">
      La culture du groupe Cleeven s’est construite autour de deux convictions
      extrêmement fortes :
    </h3>

    <h6 className="text-blue font-weight-bold">
      Nous sommes convaincus que l’excellence des Ingénieurs européens doit
      permettre à l’Europe de développer sa position de leader en terme
      d’Innovation Technologique, et de R&D.
    </h6>

    <ul>
      <li>
        En tant qu’Ingénieurs, nous participons activement aux côtés de nos
        clients à assurer leur compétitivité mondiale.
      </li>
      <li>
        Le Haut niveau d’Expertise technique, les compétences, les qualités
        humaines et relationnelles de nos Consultants permettent à nos clients
        de réaliser leurs projets.
      </li>
    </ul>

    <h6 className="text-blue font-weight-bold">
      Nous avons tous un devoir de «Conscience» et nous devons exploiter notre
      potentiel humain et intellectuel pour contribuer à un Monde meilleur.
    </h6>

    <div>
      <span className="text-black font-weight-bold">
        Notre enjeu est simple :
      </span>
      <ul>
        <li>
          Apporter les meilleures compétences technologiques et humaines, dans
          les meilleurs délais.
        </li>
        <li>
          Après un processus de recrutement très sélectif, nous accompagnons nos
          Consultants via un programme de développement personnel. Ce programme
          vise à faire prendre conscience à nos Consultants de leur Potentiel,
          et les aide à le développer. Nous voulons fortement contribuer à
          augmenter les capacités relationnelles et de communication de tous
          ceux qui nous entourent.
        </li>
      </ul>
      <span className="text-black font-weight-bold">
        Notre enjeu est extrêmement ambitieux :
      </span>
      <ul>
        <li>
          C’est notre «Mission humaine», c’est-à-dire augmenter le degré de
          conscience de ceux qui nous entourent. Tout «Simplement»...
        </li>
      </ul>
    </div>
  </div>
)

export default CultureContent
