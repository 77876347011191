import React from "react"

import iconDown from "../../images/icon-down.jpg"

const ValeurElement = ({ title, elements }) => (
  <div className="valeur btn-group d-flex flex-column justify-content-center align-items-center">
    <img className="icon" src={iconDown} alt=""/>
    <button className="btn text-black btn-lg" type="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
      {title}
    </button>
    <div className="dropdown-menu">
      {elements &&
      elements.map((element, key) => <div className="dropdown-item text-black font-weight-bold" key={key}>{element}</div>)
      }
    </div>
  </div>
)

export default ValeurElement