import React from "react"

const SocieteContent = () => (
  <div className="m-5">
    <h3 className="font-weight-bold text-black mb-4">
      Cleeven a été créée en 2015, notre projet est européen. Les sociétés du
      Groupe sont basées en France, en Suisse, en Suède et à Monaco.
    </h3>
    <div>
      <div className="mt-3 mb-3">
        <span className="text-blue font-weight-bold">Cleeven Ge</span> à
        Grenoble
      </div>
      <div className="mt-3 mb-3">
        <span className="text-blue font-weight-bold">Cleeven He</span> à Luzern,
        en Suisse
      </div>
      <div className="mt-3 mb-3">
        <span className="text-blue font-weight-bold">Cleeven Li</span> à Lyon
      </div>
      <div className="mt-3 mb-3">
        <span className="text-blue font-weight-bold">Cleeven Mo</span> à Monaco
      </div>
      <div className="mt-3 mb-3">
        <span className="text-blue font-weight-bold">Cleeven Nd</span> à Lille
      </div>
      <div className="mt-3 mb-3">
        <span className="text-blue font-weight-bold">Cleeven Sc</span> à
        Stockholm, en Suède
      </div>
      <div className="mt-3 mb-3">
        <span className="text-blue font-weight-bold">Cleeven Se</span> à Sophia
        Antipolis
      </div>
    </div>
    <a
      target="_blank"
      className="text-black font-weight-bold"
      href="/organigramme_cleeven.pdf"
    >
      >> Consulter l'organigramme
    </a>
  </div>
)

export default SocieteContent
