import React from "react"

const DressCodeContent = () => (
  <div className="text-white m-5">
    <div className="mb-5">
      <p>
        Vous êtes les ambassadeurs de Cleeven, veillez donc à adopter une tenue professionnelle lors de votre projet chez le client.
      </p>
      <p className="font-weight-bold">
        Qu'entend-on par tenue professionnelle?
      </p>
      <p>Il s'agit d'une tenue sobre, neutre et en cohérence avec les codes du monde de travail.</p>
    </div>
  </div>
)

export default DressCodeContent