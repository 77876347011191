import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import SectionTitle from "../components/sectionTitle"
import Section from "../components/section"

import SocieteContent from "../components/groupe/societeContent"
import DressCodeContent from "../components/groupe/dressCodeContent"
import CultureContent from "../components/groupe/cultureContent"
import ValeursSection from "../components/groupe/valeursSection"

import groupIcon from "../images/picto-groupe.png"
import CultureImage from "../images/photo-culture.jpg"
import dressCodeImage from "../images/photo-dress-code.jpg"
import societeImage from "../images/photo-societe.jpg"
import SocialButtons from "../components/socialButtons"

const GroupePage = () => (
  <Layout>
    <SocialButtons />
    <SEO title="Présentation du Groupe Cleeven" />
    <div id="presentation-content" className="row">
      <Hero text="Culture et Valeurs" id="culture" />
      <SectionTitle iconImg={groupIcon} text="Présentation du groupe" />
      <Section
        imageUrl={CultureImage}
        title="Culture"
        bgColor="#f3f3f3"
        minHeight={900}
      >
        <CultureContent />
      </Section>
      <ValeursSection />
      <Hero id="valeurs" text="" />
      <Section
        title="Dress Code"
        bgColor="#408bc7"
        imageUrl={dressCodeImage}
        inversed
        whiteText
      >
        <DressCodeContent />
      </Section>
      <Section
        title="Sociétés et Organigramme"
        bgColor="#ffffff"
        imageUrl={societeImage}
        minHeight={900}
      >
        <SocieteContent />
      </Section>
    </div>
  </Layout>
)

export default GroupePage
